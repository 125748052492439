<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar                         
            v-on:toolbar-button-click="onToolbarClick"  
        > 
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="true"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

        <OutgoingRegisteredExFilterDlg
            v-if="['OutgoingsUnsended', 'OutgoingsSended'].includes(collection)"
            v-model="extendedTableFilter" 
            ref="OutgoingRegisteredExFilterDlgRef" 
        />

        <OutgoingUnregisteredExFilterDlg
            v-if="['OutgoingsOnRegistration', 'OutgoingsRejected'].includes(collection)"
            v-model="extendedTableFilter" 
            ref="OutgoingUnregisteredExFilterDlgRef"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapActions } from 'vuex';

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';

import OutgoingsOnRegistration from './OnRegistration.vue';
import OutgoingsSended from './Sended.vue';
import OutgoingsUnsended from './Unsended.vue';
import OutgoingsRejected from './Rejected.vue';

import OutgoingRegisteredExFilterDlg from '@/components/documents/dialogs/extended-filters/outgoingRegistered.vue';
import OutgoingUnregisteredExFilterDlg from '@/components/documents/dialogs/extended-filters/outgoingUnregistered.vue';

export default {
    name: "Outgoings",
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,
        ExtendedFilterPanel,
        OutgoingsOnRegistration,
        OutgoingsSended,
        OutgoingsUnsended,
        OutgoingsRejected,
        OutgoingRegisteredExFilterDlg,
        OutgoingUnregisteredExFilterDlg
    },
    data () {
        return {
            cancellationTokenSource: null,
            dataSource: null
        }
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['edslight/outgoings/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('edslight/outgoings/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['edslight/outgoings/getStatus']
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") 
                    this.$refs.currentComponent.resetPage();

                if (newValue)
                    this.$store.commit('edslight/outgoings/PUSH_STATUS', { collection: this.collection, value: newValue });
            }
        },
        search: {
            get: function() {
                return this.$store.getters['edslight/outgoings/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('edslight/outgoings/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        filterDataSource() {
            return this.$store.getters['edslight/outgoings/getFilter'];
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['edslight/outgoings/getExtendedFilter'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();
                
                if (newValue)
                    this.$store.commit('edslight/outgoings/PUSH_EXTENDED_FILTER', { collection: this.collection, value: newValue });
            }
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('edslight/outgoings', ['prepareDefaults']),
        onToolbarClick(event, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                this.$notify.alert(`Действие ${btn.Action} не реализовано`);
        },
        async loadButtons() {
            this.setOverlayVisible({ visible: true });
            this.$eventBus.$emit('update_edslight_counters');
            
            if (this.cancellationTokenSource)
                this.cancellationTokenSource.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSource = Axios.CancelToken.source();

            let type = '';

            switch (this.collection)
            {
                case 'OutgoingsOnRegistration':
                case 'OutgoingsRejected':
                    type = 'Chancellery.Document'; break;

                default:
                    type = 'Documents.Correspondence'; break;
            }

            let response = await httpAPI({
                url: `api/actions/collection?type=${type}`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.Payload;
                this.$store.commit('documents/toolbar/SET_MENU', {menu: this.dataSource.Data.Menu });
            }
            
            this.setOverlayVisible({ visible: false });
        },
        async loadFilters() {
        },
        async onFilterClick(){
            try
            {
                switch(this.collection)
                {
                    case "OutgoingsUnsended":
                    case "OutgoingsSended":
                        await this.$refs.OutgoingRegisteredExFilterDlgRef.open();
                        break;

                    case "OutgoingsOnRegistration":
                    case "OutgoingsRejected":
                        await this.$refs.OutgoingUnregisteredExFilterDlgRef.open();
                        break;

                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        },
        async Export() {
            let filter = this.$refs.currentComponent.getTableFilterObject();
            delete filter.Limit;
            delete filter.Offset;
            let exportURI = `api/download/export?param=${JSON.stringify(filter)}`;
            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка исходящих документов от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
        }
    },
    watch: {
        collection: function(newValue, oldValue) {
            if (oldValue != null)
                this.loadButtons();
        }
    },
    async created() {
        await this.prepareDefaults();
        await this.loadButtons();
    }
}
</script>